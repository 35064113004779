import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import GPS_TRACKING_IMAGE from "../images/customImages/GPS_TRACKING.png";
import PTL_HOME_IMAGE from "../images/customImages/PTL_HOME.png";
import BMS_ACCOUNTS_MAIN_IMAGE from "../images/customImages/BMS_ACCOUNTS_MAIN.png";
import YO_DAILY_ADMIN_DASHBOARD from "../images/customImages/YO_DAILY_ADMIN_DASHBOARD.png";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      {/* <Features /> */}
      {/* <Pricing /> */}
      <MainFeature
        subheading="Finance"
        heading="Billing Management System"
        description="It is an internal Tool, where employees raise day to day expenses as tickets with attachments and Finance team have access to all tickets and process the payment after getting approval from managers.It has 3 different logins & UI each for Employee, Manager and Finance team."
        imageSrc={BMS_ACCOUNTS_MAIN_IMAGE}
        buttonRounded={false}
      />
      <MainFeature
        subheading="E commerce"
        heading="YoDaily Admin Dashboard"
        description="An Internal Admin Dashboard developed for an ecommerce project as a freelance Project which delivers day to day milk products to customers in Ongole Town, Andhra Pradesh."
        imageSrc={YO_DAILY_ADMIN_DASHBOARD}
        buttonRounded={false}
        textOnLeft={false}
      />
      <MainFeature
        subheading="Internal"
        heading="GPS Tracking"
        description="Internal Application developed for live tracking of all vehicles which are having gps device fixed. Employee can edit vehicle number if the vehicle got replaced and can generate reports in excel format for selected time range"
        imageSrc={GPS_TRACKING_IMAGE}
        buttonRounded={false}
      />
      <MainFeature
        subheading="Logistics"
        heading="Client Dashboard"
        description="A Platform developed for our clients where they can place order and can track the order from pickup to delivery. Our supervisors can download shipping labels which are generated automatically in server while placing order. After delivery using the same tool, supervisors upload POD which will reflect to clients"
        imageSrc={PTL_HOME_IMAGE}
        buttonRounded={false}
        textOnLeft={false}
      />
      <Testimonial />
      <Blog />
      <ContactUsForm />

      {/* <Testimonial /> */}
      {/* <FAQ /> */}
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
}
